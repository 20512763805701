import React from "react"

const Button = props => {
  return (
    <button className="main-button">
      <span className="span">{props.name}</span>
    </button>
  )
}

export default Button
